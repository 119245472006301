$dist: '/assets/dist/';
//-- typo:start --//
$base-font-family: 'Open Sans', sans-serif; //400, 400 italic, 700, 700 italic
$heading-font-family: 'Open Sans', sans-serif; //400, 400 italic
//-- typo:end --//
// Font Sizes
$base-font-size: 16px;
$base-rem-ratio: 16;


// Line height
$base-line-height: 1.5;
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 5px;
$base-spacing: $base-line-height * 2em;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

// Colors
//-- colors:start --//

$yellow: #F1CA2F;
$dark-blue: #4D5A6A;
$green: #6FCF97;

$red: #EB5757;

$darker-gray: #4A4A4A;
$dark-gray: #828282;
$medium-gray: #B8B8B8;
$light-gray: #F0F0F0;

$gray: #E0E0E0;

//-- colors:end --//
$primary: $yellow;
$secondary: $dark-gray;
// Font Colors
$base-font-color: #85827A;
$action-color: $dark-blue;

// Border
$base-border-color: $medium-gray;
$base-border: 1px solid $base-border-color;

// Background Colors
$base-background-color: #fff;
$secondary-background-color: $light-gray;

// Forms
$form-box-shadow: none;
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($action-color, $lightness: -5%, $alpha: -0.3);

// Animations
$base-duration: 150ms;
$base-timing: ease;
$intro-duration: 1s;

// Breakpoints
// (based on chrome dev tools breakpoints)
$fourk: 2560px;
$laptop-l: 1440px;
$laptop: 1024px;
$tablet: 768px;
$tablet-s: 549px;
$mobile-l: 425px;
$mobile-m: 375px;
$mobile-s: 320px;

// Grid Variables
// Number of columns
$columns: 12 !default;

//Width of a column in pixels
$column-width: 100px !default;

//Width of a gutter in pixels
$gutter-width: 20px !default;

//Max width
$grid-width: ($columns * $column-width) + (($columns ) * $gutter-width);
$max-width: $grid-width;

$full-column: $column-width + ($gutter-width * 2);
