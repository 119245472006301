.Modal-wrapper {
    position:fixed;
    display:table;
    height:100%;
    width:100%;
    top:0;
    left:0;
    transform:scale(0);
    z-index:1;

    &.active {
        transform:scaleY(.01) scaleX(0);
        animation:unfoldIn 1s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;

        .Modal-background {
            .Modal {
                transform:scale(0);
                animation: zoomIn .5s .8s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
            }
        }
    }

    &.exit {
        transform:scale(1);
        animation:unfoldOut 1s .3s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
        .Modal-background {
            .Modal {
                animation: zoomOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
            }
        }
    }
}

.Modal-background {
    background:rgba(0,0,0,.8);

    height: 100vh;
}

.Modal {
    background:white;
    padding: $gutter-width * 1.5;
    
    border-radius:3px;
    font-weight:300;
    position:relative;

    &-close {
        // position: absolute;
        // top: 3px;
        // position: relative;

        img {
            position: absolute;
            margin-top: -10px;
        }
    }
}



@keyframes unfoldIn {
    0% {
        transform:scaleY(.005) scaleX(0);
    }
    50% {
        transform:scaleY(.005) scaleX(1);
    }
    100% {
        transform:scaleY(1) scaleX(1);
    }
}

@keyframes unfoldOut {
    0% {
        transform:scaleY(1) scaleX(1);
    }
    50% {
        transform:scaleY(.005) scaleX(1);
    }
    100% {
        transform:scaleY(.005) scaleX(0);
    }
}

@keyframes zoomIn {
    0% {
        transform:scale(0);
    }
    100% {
        transform:scale(1);
    }
}

@keyframes zoomOut {
    0% {
        transform:scale(1);
    }
    100% {
        transform:scale(0);
    }
}
