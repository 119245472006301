table {
  margin: $small-spacing 0;
  width: 100%;
}

th {
  border-bottom: 1px solid shade($base-border-color, 25%);
  font-weight: 600;
  padding: 20px 0;
  text-align: left;
  vertical-align: bottom;
  color: $dark-gray;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: rem(14);
}

td {
  border-bottom: $base-border;
  padding: $small-spacing 0;
}

th label {
  margin: 0;
}
