html {
  /* box-sizing: border-box;
  border: 24px solid $red;
  height: auto;
  min-height: 100%;

  @include breakpoint( max-width $tablet ) {
	  border-width: 12px;
  } */
}
*, *:before, *:after {
  box-sizing: inherit;
}

/* ==========================================================================
   General Layout
   ========================================================================== */
section,
section.container{
	padding-top: $base-spacing;
	padding-bottom: $base-spacing;
}

/* ==========================================================================
   Header
   ========================================================================== */
header {
  margin: $gutter-width + ($gutter-width / 4) auto !important;

  @include breakpoint ( max-width $tablet ) {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.SiteLogo {
  @include breakpoint ( max-width $tablet ) {
    width: 150px;
    height: auto;
  }
}

.SiteNav {
  // width: 100%;
  // transition: all 0.65ms ease-in-out;

  &Misc {
    margin-bottom: $gutter-width + ($gutter-width / 4);

    &-myPPC {

      cursor: pointer;

      background-color: rgba(#f2f2f2, 0.83);
      padding: 4px 13px;
      &:before {
        content: ' ';
        display: inline-block;
        width: 12px;
        height: 12px;

        margin-right: 6px;

        background: {
          image: url($dist + 'images/icons/myppc.svg');
          repeat: no-repeat;
          position: top left;
        }
      }
    }
  }

  &Menu {
    // transition: all 0.65ms ease-in-out;
  
    li {
      // margin-right: $gutter-width * 2;

      &.MobileItem {
        display: none;
      }

      &:last-child, &.ContactItem {
        margin-right: 0;
      }

    }
  }

  @include breakpoint( max-width $tablet ) {

    &Misc, .MobileHide {
      display: none;
    }

    &Menu {
      transition: max-height 0.65s ease-in-out;
      background: $dark-blue;

      position: absolute;

      top: 70px;
      left: 0;

      width: 100%;
      max-height: 0;

      overflow: hidden;

      flex-direction: column;
      z-index: 50;

      > li {
        margin-right: 0;
        width: 100%;
        padding: ($gutter-width / 2) $gutter-width;


        border: {
          bottom: 1px solid rgba(black, 0.13);
          top: none !important;
          left: none !important;
          right: none !important;
        }

        a {
          color: white;
          width: 100%;
        }

        &.MobileItem {
          display: unset;
        }
        
        
        &:first-child {
          padding-top: $gutter-width;
        }

        &:last-child {
          padding-bottom: $gutter-width / 2;
        }
      }

      &.active {
        max-height: 5000px;
        height: auto;
      }
    }
  } //end of breakpoint
}

.ProductsAccordion {

  transition: max-height 0.65s ease-in-out;

  width: 100%;
  max-height: 0;

  overflow: hidden;

  flex-direction: column;

  background-color: #323943;

  &-title {
    color: white;
    cursor: pointer;
  }

  &-icon {
    transition: all 350ms ease-in-out;

    &.active {
      transform: scaleY(-1);
    }
  }

  &.active {
    max-height: 10000px;
    height: auto;
  }
}

.MobileSubMenu {
  &-subTitle {
    padding: 0 $gutter-width;
    margin-bottom: $gutter-width / 2;
    margin-top: rem(30);

    color: rgba(white, 0.5);
    // cursor: pointer;
    text-transform: uppercase;
    font-size: rem(16);
  }

  &-list {
    padding: 0 $gutter-width;

    li {
      a {
        font-size: rem(15);
        color: white;
      }
    }

    &:last-child {
      padding-bottom: $gutter-width;
    }
  }
}

.MobileNav {

	display: none;
	visibility: hidden;

	&-icon {
		position: absolute;
		z-index: 55;

		width: 32px;
		height: 32px;

		transform: rotate(0deg);
		transition: .5s ease-in-out;
		cursor: pointer;

		top: $gutter-width;
		right: $gutter-width;

		span {
			display: block;
			position: absolute;
			height: rem(4.5);
			width: 50%;
			background: $dark-blue;
			opacity: 1;
			transform: rotate(0deg);
			transition: .25s ease-in-out;

			&:nth-child(even) {
				left: 50%;
				border-radius: 0 9px 9px 0;
			}

			&:nth-child(odd) {
				left:0px;
				border-radius: 9px 0 0 9px;
			}

			&:nth-child(1), &:nth-child(2) {
				top: 0px;
			}

			&:nth-child(3), &:nth-child(4) {
				top: 9px;
			}

			&:nth-child(5), &:nth-child(6) {
				top: 18px;
			}
		}

		&.active {
			// top: 20px;
			// right: 20px;
			span {
				// background: white;

				&:nth-child(1), &:nth-child(6) {
					transform: rotate(45deg);
				}

				&:nth-child(2), &:nth-child(5) {
					transform: rotate(-45deg);
				}

				&:nth-child(1) {
					left: 5px;
					top: 7px;
				}

				&:nth-child(2) {
					left: calc(50% - 5px);
					top: 7px;
				}

				&:nth-child(3) {
					left: -50%;
					opacity: 0;
				}

				&:nth-child(4) {
					left: 100%;
					opacity: 0;
				}

				&:nth-child(5) {
					left: 5px;
					top: 13px;
				}

				&:nth-child(6) {
					left: calc(50% - 5px);
					top: 13px;
				}
			}
		}
	}


	@include breakpoint( max-width $tablet ) {
		display: block;
		visibility: visible;
	}

}

.MobileSearch {
  // padding: $gutter-width 0;
  // padding-bottom: $gutter-width;

  .SearchResultsForm {
    &-input {
        input {
          background-color: #343E4A;
          height: 36px;
          border: none;
          color: white;
          font-size: rem(15);

          &::placeholder {
            font-style: italic;
            color: rgba(white, 0.3);
          }
        }

        &::before {
          background: {
            image: url($dist + '/images/icons/mobile-search.svg');
            repeat: no-repeat;
            position: top left;
        }
      }
    }
  }
}

/* ==========================================================================
   Search Button
   ========================================================================== */
.SiteSearch {
  // width: 90px;
  max-width: 150px;
  max-height: 35px;
  position: relative;
  text-align: left;
  background-color: rgba(#f2f2f2, 0.83);

  padding: 10px 0 10px 15px;
  margin-right: $gutter-width / 4;

  &-icon {
    width: 12px;
    height: 12px;

    margin-right: ($gutter-width / 2);
  }

  label {
    position: absolute;
    left: 35px;
    transition: opacity 0.55s ease;
    color: $dark-blue;
  }

  input.Search {
    transition: width .55s ease;
    
    width: 70px;
    max-height: 26px;

    padding: 0;

    // padding-top: 0;
    // padding-bottom: 0;
    
    color: $dark-blue;
    background-color: rgba(#f2f2f2, 0.83);
  
    outline: none;
    border: none;
  }

  &:hover, &:focus {
    input.Search {
      width: 100px;
      outline: inherit;
      border: inherit;
    }

    label {
      opacity: 0;
    }
  }
  
}

/* ==========================================================================
   Footer
   ========================================================================== */
.MainFooter {
  background-color: $dark-blue;
  padding:$gutter-width 0;
  margin-top: $gutter-width * 3;
}

.FooterNav {
  li {
    margin-right: (($gutter-width * 2) + ($gutter-width / 2));

    a {
      color: white;
    }

    &:last-child {
      margin-right: none;
    }
  }

  @include breakpoint( max-width $tablet ) {
    display: none;
  }
}

.FooterSocial {
  li {
    margin-right: ($gutter-width / 2) + ($gutter-width / 4);

    &:last-child {
      margin-right: 0;
    }
  }


  @include breakpoint( max-width $tablet ) {
    width: 130px;
    margin-top: $gutter-width;
    margin-left: auto;
    margin-right: auto;

    li {
      // width: 33%;
      margin-right: 0;
    }
  }
}

.FooterSecondary {
  padding-top: $gutter-width;
  padding-bottom: $gutter-width * 3;
  background-color: $light-gray;

  @include breakpoint ( max-width $tablet ) {
    padding-bottom: ($gutter-width * 3) / 2;
  }

  @include breakpoint ( max-width $tablet ) {
    p {
      font-size: rem(13);
    }
  }
}

.ConflictMinerals {
  padding-bottom: 0;
  margin-bottom: 0;

  p {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  @include breakpoint( max-width $tablet ) {
    width: 100%;
    border-left: 0;
    border-right: 0;
    border-top: 1px solid $gray;
    border-bottom: 1px solid $gray;

    align-items: flex-start;
    justify-content: center;

    padding: ($gutter-width + 10) 0;
  }
}

.ISO {
  @include breakpoint( max-width $tablet ) {
    margin-top: 25px;
  }
}

.LeafLogo {
  max-height: 40px;
  height: 100%;
  width: auto;
  margin-right: ($gutter-width / 2) + ($gutter-width / 4);
}



/* ==========================================================================
   Main
   ========================================================================== */

main {
  margin-top: rem(50);

  @include breakpoint ( max-width $tablet ) {
    margin-top: $gutter-width * 1.5;
  }
}

.Content {
  padding-right: rem(35);

  img {
    float: left;
    margin-right: 20px;
  }
  
  @include breakpoint ( max-width $tablet ) {
    padding-right: 0;
  }
}

/* ==========================================================================
   Sidebar
   ========================================================================== */
.Sidebar {
  padding: 0;
  // max-width: 280px;

  @include breakpoint( max-width $tablet ) {
    // max-width: 100%;
  }
}

.SidebarCta {
  background-color: $dark-blue;
  padding: rem(25) rem(30);

  &-ready {
    color: white;
    margin-bottom: rem(17);
  }

  &-btn {
    color: $darker-gray;
    font-size: rem(14);
    font-weight: bold;
  }  

  @include breakpoint( max-width $tablet ) {
    &-ready {
      margin-bottom: $gutter-width / 2;
      width: 100%;
      text-align: center;
    }
  }
}

.ApplyForPosition {
  margin-top: 10px;
  
 &-btn {
    color: $darker-gray;
    font-size: rem(14);
    font-weight: bold;
  }  
}

.SidebarMenu {
  margin-top: ($gutter-width / 2);

  border: 1px solid $gray;
  border-radius: 0;
  padding: $gutter-width +  ($gutter-width / 2);

  &-mainTitle, &-subTitle {
    font-weight: normal;
  }

  &-mainTitle {
    font-size: rem(15);
    margin-bottom: rem(20);
  }

  &-subTitle {
    font-size: rem(14);
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: ($gutter-width / 2);
  }

  &-list {
    li {
      margin-bottom: rem(1);
      a {
        font-size: rem(13);
        text-decoration: underline;
        // line-height: rem(25);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  hr {
    margin: rem(30) 0;
  }
}

.ArchiveMenu {
  margin-top: $gutter-width + ($gutter-width / 2);
  padding: ($gutter-width / 4) + $gutter-width;
  background-color: #f2f2f2;

  &-mainTitle {
    font: {
      size: rem(18);
      weight: bold;    
    }

    color: $darker-gray;

    margin-bottom: $gutter-width;
  }
}

.ArchiveYear {
  font-weight: normal;
  text-decoration: underline;
  margin-bottom: rem(15); 
}

/* ==========================================================================
   PageBanner / PageBanner
   ========================================================================== */
.PageSlideshow, .PageBanner, .GallerySlideshow {
  position: relative;

  img {
      width: 100%;
      height: auto;
  }

  @include breakpoint ( max-width $tablet ) {
    padding-left: 0;
    padding-right: 0;

    // min-height: 165px;
  }
}

.PageSlideshowCaption {
  background-color: rgba(#2B2B2B, 0.84);
  position: absolute;
  max-width: 550px;
  bottom: 50px;
  padding: 15px 25px;
  // color: white;

  &-title, p, p strong {
      color: white;
  }

  &-title {
      font-weight: bolder;
  }

  p {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }

  @include breakpoint ( max-width $tablet ) {
    max-width: 100%;
    // top: 100%;
    bottom: 0;
    // margin-top: 100%;
    position: relative;

    background-color: #2B2B2B;
  }
}

.GallerySlideshowNav {
  div > div {
    margin-right: $gutter-width / 2;
  }
}


/* ==========================================================================
   Content
   ========================================================================== */

.Content {
  padding-left: 0;
}


/* ==========================================================================
   SpeakWithExpertForm
   ========================================================================== */
 
.SpeakWithExpertForm {
  padding-left: 0;
  padding-right: 0;

  margin-top: $gutter-width;

  input, textarea {
    margin-bottom: $gutter-width;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.SubmitExpert {
  color: $darker-gray;
  font-size: rem(14);
  font-weight: bold;
}

