// // DO NOT EDIT DIRECTLY!
////Generated by gulpfile.js/tasks/iconFont.js
////from gulpfile.js/tasks/iconFont/template.sass

@font-face {
  font-family: icons;
  src: url("/assets/dist/fonts/icons.eot");
  src: url("/assets/dist/fonts/icons.eot?#iefix") format('embedded-opentype'), url("/assets/dist/fonts/icons.woff") format('woff'), url("/assets/dist/fonts/icons.ttf") format('truetype'), url("/assets/dist/fonts/icons.svg#icons") format('svg');
  font-weight: normal;
  font-style: normal; }

@mixin icon($content: '') {
  &:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    content: $content;
    font-family: 'icons';
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    speak: none;
    vertical-align: middle;
    text-transform: none;
    @content; } }

.icon {
  @include icon; }

// Save variable
$icon-check: "\EA01";
// Save mixin
@mixin icon--check {
  @include icon($icon-check) {
    @content; } }
// Expose as class
.icon.icon--check:before {
  content: $icon-check; }

// Save variable
$icon-email: "\EA02";
// Save mixin
@mixin icon--email {
  @include icon($icon-email) {
    @content; } }
// Expose as class
.icon.icon--email:before {
  content: $icon-email; }

// Save variable
$icon-facebook: "\EA03";
// Save mixin
@mixin icon--facebook {
  @include icon($icon-facebook) {
    @content; } }
// Expose as class
.icon.icon--facebook:before {
  content: $icon-facebook; }

// Save variable
$icon-google: "\EA04";
// Save mixin
@mixin icon--google {
  @include icon($icon-google) {
    @content; } }
// Expose as class
.icon.icon--google:before {
  content: $icon-google; }

// Save variable
$icon-instagram: "\EA05";
// Save mixin
@mixin icon--instagram {
  @include icon($icon-instagram) {
    @content; } }
// Expose as class
.icon.icon--instagram:before {
  content: $icon-instagram; }

// Save variable
$icon-leftArrow: "\EA06";
// Save mixin
@mixin icon--leftArrow {
  @include icon($icon-leftArrow) {
    @content; } }
// Expose as class
.icon.icon--leftArrow:before {
  content: $icon-leftArrow; }

// Save variable
$icon-phone: "\EA07";
// Save mixin
@mixin icon--phone {
  @include icon($icon-phone) {
    @content; } }
// Expose as class
.icon.icon--phone:before {
  content: $icon-phone; }

// Save variable
$icon-twitter: "\EA08";
// Save mixin
@mixin icon--twitter {
  @include icon($icon-twitter) {
    @content; } }
// Expose as class
.icon.icon--twitter:before {
  content: $icon-twitter; }


