@include font-face(
	"Scala W01",
	$dist + "fonts/1378152/1378152",
	("eot", "woff2", "woff", "ttf")
	) {
	font-style: regular;
	font-weight: regular;
}
@include font-face(
	"Scala W01",
	$dist + "fonts/1378158/1378158",
	("eot", "woff2", "woff", "ttf")
	) {
	font-style: italic;
	font-weight: regular;
}
@include font-face(
	"Scala Sans W01",
	$dist + "fonts/1378188/1378188",
	("eot", "woff2", "woff", "ttf")
	) {
	font-style: regular;
	font-weight: bold;
}
@include font-face(
	"Scala Sans W01",
	$dist + "fonts/1378194/1378194",
	("eot", "woff2", "woff", "ttf")
	) {
	font-style: italic;
	font-weight: bold;
}
@include font-face(
	"Scala Sans W01",
	$dist + "fonts/1378236/1378236",
	("eot", "woff2", "woff", "ttf")
	) {
	font-style: regular;
	font-weight: regular;
}
@include font-face(
	"Scala Sans W01",
	$dist + "fonts/1378242/1378242",
	("eot", "woff2", "woff", "ttf")
	) {
	font-style: italic;
	font-weight: regular;
}

html{
	font-size: $base-font-size;
}

body {
	color: $base-font-color;
	font-family: $base-font-family;
	font-size: $base-font-size;
	line-height: $base-line-height;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@include breakpoint(max-width $tablet) {
	html{
		font-size: 14px;
	}
}
@include breakpoint(max-width $mobile-l) {
	html{
		font-size: 12px;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $heading-font-family;
	font-size: modular-scale(1);
	line-height: $heading-line-height;
	font-style: normal;
	font-weight: normal;
	margin: 0;
	color: $dark-gray;
}

h1{
	font-size: rem(32);
	font-weight: 700;
	color: $darker-gray;
}

h2{
	font-size: rem(27);
}

h3{
	font-size: rem(24);
}

h4{
	font-size: rem(20);
}

h5{
	font-size: 1rem;
}

h6{
	font: normal normal rem(14)/1 $base-font-family;
}

h1+p,
h2+p,
h3+p{
	margin-top: rem(20);
}



p {
	margin: 0 0 $small-spacing;
	line-height: rem(33);

	&.lead {
		font-size: 19px;
	}
}

strong{
	font-weight: bold;
	color: $darker-gray;
}

a {
	color: $action-color;
	text-decoration: none;
	transition: color $base-duration $base-timing;

	&:active,
	&:focus,
	&:hover {
		color: shade($action-color, 25%);
	}
}

hr {
	border-bottom: $base-border;
	border-left: 0;
	border-right: 0;
	border-top: 0;
	margin: $base-spacing 0;
}

.text--white{color: white}
.text--black{color: $dark-gray}
.text--left{text-align:left}
.text--center{text-align:center}
.text--right{text-align:right}
.text--capitalize{text-transform:capitalize}
.text--uppercase{text-transform: uppercase}
.text--noTransform{text-transform: none}
.text--normal{font-style: normal;}
