.Article {
    margin-bottom: rem(55);
    max-width: 370px;
    padding: 0;

    &-lead {
        max-width: 100%;
        width: 100%;

        img {
            float: none;
            margin-right: unset;
            width: 100%;
            height: auto;
        }

        &--date {
            font-style: italic;
            margin-top: 35px;
            // margin-bottom: 15px;
        }       

        &--title {
            // margin-top: 15px;
            margin-bottom: $gutter-width;
        }

        &--body {
            img {
                float: left !important;
                margin-right: $gutter-width !important;
            }
        }
    }

    &-detail {
        &--title {
            margin-top: 15px;
            margin-bottom: $gutter-width;
        }

        &--photo {
            float: none !important;
            margin-right: 0 !important;
            width: 100%;
            height: auto; 
        }
    }

    &-readMore {
        // margin-bottom: rem(55);
        color: #434343;
        padding: ($gutter-width / 2) (($gutter-width * 2) + ($gutter-width / 2));
    }

    &-info {
        > * {
            width: 100%;
        }
    }

    @include breakpoint( max-width $laptop ) {
        max-width: 100%;
    }
}

.MoreNews {
    padding-top: rem(55);
    margin-top: rem(55);

    border-top: 1px solid $gray;
}

.Pages {
    &-wrapper {
        // max-width: 150px;
        // width: 100%;
        margin:0 auto;
    }

    a {
        text-decoration: underline;
    }
}

.Page {
    margin-right: 15px;

    &:last-child {
        margin-right: 0;
    }

    a.active {
        font-weight: bold;
    }
}