fieldset {
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
}

legend, label {
  display: block;
  font-weight: normal;
  font-size: rem(15);
  color: $dark-gray;
  margin-bottom: $small-spacing / 4;
  margin-right: $small-spacing;
  line-height: 1;
}

.sublabel {
  font-size: rem(12);
  color: #adabac;
  display: block;
  font-weight: bold;
  letter-spacing:2px;
  text-transform: uppercase;
  line-height: 1.9;
}


input,
select,
textarea {
  display: block;
  font-family: $base-font-family;
  font-size: $base-font-size;
  border-radius: 2px;
}

#{$all-text-inputs} {
  appearance: none;
  background-color: $base-background-color;
  border: $base-border;
  border-radius: 3px;
  box-shadow: none;
  box-sizing: border-box;
  padding: 12px;
  transition: border-color $base-duration $base-timing;
  width: 100%;
  font-size: rem(15);

  &:hover {
    border-color: shade($base-border-color, 20%);
  }

  &:focus {
    border-color: $action-color;
    // box-shadow: 0 0 5px rgba(0,66,88,0.45);
    outline: none !important;
  }

  &:disabled {
    background-color: shade($base-background-color, 5%);
    cursor: not-allowed;

    &:hover {
      border: $base-border;
    }
  }

  &::placeholder {
    color: tint($base-font-color, 40%);
  }
}

textarea {
  resize: vertical;
  font-size: rem(18);
}

[type="checkbox"],
[type="radio"] {
  display: inline;
  margin-right: $gutter-width / 2;
}

[type="file"] {
  margin-bottom: $small-spacing;
  width: 100%;
}

input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
}

select {
  margin-bottom: $small-spacing;
  width: 100%;
  height: 56px;
  background: #fff;
  font-size: rem(18);

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

// .error,
// .is-error {
//   border: 1px solid #af4034;


//   &:focus{
//   	border: 1px solid #af4034;
//   }
// }

// .errorMessage,
// span.is-error,
// label.is-error {
//   color: #af4034;
//   font-style: italic;
//   border: none;
//   display: block;
//   margin-top: 5px;
// }

// input.is-error + label {
// 	color: #af4034;
// }

input, select, textarea {
	&.is-error {
		border: 1px solid $red !important;
		background-color: rgba($red, 0.12);
	}
}

label.is-error {
	color: $red;
	margin-right: 0;

	&:after {
		display: inline-block;
		content: 'This value is required';
		// background: green;
		width: auto;
		align-self: flex-end;
		float: right;
		font-size: rem(15);
		font-style: italic;
	}
}

span.is-error {
	color: $red;
	font-style: italic;
	border: none;
	font-size: rem(15);
	text-align: right;
}

.sublabel + .is-error--list{
	margin-top: -.5*$gutter-width;
	display: block;
}

form ul li {
  margin-bottom: 30px;
}

input[type="radio"] + label, input[type="checkbox"] + label {
  display: inline-block;
}

form fieldset .flex .col {
  padding-left: 0;
}
