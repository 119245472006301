#{$all-buttons},
.btn {
	appearance: none;
	background-color: white;
	border: 2px solid $primary;
	border-radius: $base-border-radius;
	color: $primary;
	cursor: pointer;
	display: inline-block;
	font-family: $base-font-family;
	font-size: 1rem;
	-webkit-font-smoothing: antialiased;
	font-weight: normal;
	line-height: 1;
	padding: $small-spacing*.5 $small-spacing;
	text-align: center;
	text-decoration: none;
	letter-spacing: 1px;
	transition: background-color $base-duration $base-timing, border-color $base-duration $base-timing;
	user-select: none;
	vertical-align: middle;
	white-space: nowrap;
	max-width: 100%;

	overflow: hidden;
	text-overflow: ellipsis;

	outline: none !important;

	&:hover,
	&:focus {
		color: #fff;
		background-color: $primary;
	}

	&:disabled {
		cursor: not-allowed;
		opacity: 0.5;
	}

	@include breakpoint( max-width $laptop ){
		padding: $small-spacing*.5 $small-spacing;
	}
}

.btn--primary {
	background-color: $primary;
	color: white;

	&:hover, &:focus {
		background-color: darken($primary, 15%);
		border-color: darken($primary, 15%);
		color: white;
	}
}

.btn--secondary {
	background-color: white;
	color: $primary;

	&:hover, &:focus {
		background-color: white;
		border-color: darken($primary, 15%);
		color: darken($primary, 15%);
	}
}

.btn--tertiary {
	background-color: white;
	border-color: $secondary;
	color: $secondary;

	&.active {
		border: 2px solid $primary !important;
		background-color: $primary !important;
		color: white;
	}

	&:hover, &:focus {
		background-color: darken($secondary, 30%);
		border-color: darken($secondary, 30%);
		color: white;
	}
}

.btn--gray {
	background-color: $medium-gray;
	border-color: $medium-gray;
	color: white;
		&:hover, &:focus {
			background-color: darken($medium-gray, 30%);
			border-color: darken($medium-gray, 30%);
			color: white;
		}
}

.btn--full {
	width: 100%;
}

@import '../../vendor/ladda/css/ladda';
