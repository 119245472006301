.SearchResultsTitle {
    margin-bottom: rem(40);
    padding: 0;
}

.SearchResultsForm {

    background-color: $gray;
    padding: $gutter-width;

    &-input input, &-submit {
        height: rem(36);
    }

    &-input {
        padding-left: 0;
        position: relative;

        input {
            padding-left: $gutter-width + 10;
        }

        &::before {
            content: ' ';
            display: flex;
            width: 16px;
            height: 16px;
            
            background: {
                image: url($dist + '/images/icons/search.svg');
                repeat: no-repeat;
                position: top left;
            }

            position: absolute;
            top: 33%;
            left: 10px;
        }
    }

    &-submit {
        padding-top: 0;
        padding-bottom: 0;

        color: #434343;
        font-weight: bold;
    }

    @include breakpoint ( max-width $mobile-l ) {
        &-submit {
            margin-top: $gutter-width;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.TotalResults {
    margin: $gutter-width * 2 0;

    &-term, &-error {
        font-style: italic;
    }
}

.SearchResult {
    border-bottom: 1px solid $gray;
    margin-top: rem(36);

    &-title {
        color: #4D5A6A;
        font-weight: bold;
    }

    &-channel {
        margin-bottom: $gutter-width;
        padding-bottom: 0;

        font-style: italic;
    }

    &-preview {
        margin-bottom: rem(36);
    }
}