/* ==========================================================================
   Ticker
   ========================================================================== */
.Ticker {
    background-color: #3B3E43;
    border-radius: 3px;
    height: 30px;

    marquee {
        padding-top: 2px;
        width: 100% !important;
    }

    &-entry, .entry {
        color: white;

        margin-right: $gutter-width;

        strong {
            color: #ccc;

            margin: 0 5px;
        }

        &--green, &--red, .green, .red {
            margin-left: 5px;
        }

        &--green, .green {
            color: #00FF00;
        }

        &--red, .red {
            color: #FF0000;
        }
    }

    @include breakpoint( max-width $tablet ) {
        display: none;
    }
}
  

/* ==========================================================================
   Stocks
   ========================================================================== */

.Stocks {
    &-title {
        margin-bottom: $gutter-width * 2;
    }

    &-price {
        text-align: center;
    }

    &-color {
        text-align: right;
    }

    &-entry {

        &--green {
            color: $green;

            &:after {
                content: ' ';
                display: inline-block;
                width: 10px;
                height: 10px;
                background: $green;
                border-radius: 50%;
                margin-left: 10px;
            }
        }

        &--red {
            color: $red;

            &:after {
                content: ' ';
                display: inline-block;
                width: 10px;
                height: 10px;
                background: $red;
                border-radius: 50%;
                margin-left: 10px;
            }
        }

        &:first-child {
            // The first child of the entries being generated by the {home-ticker} loop is unstamped and has to be targetted by a pseudo element
            font-style: italic !important;
            font-weight: normal !important;
            margin-bottom: $gutter-width * 3;

            strong {
                font-weight: normal !important;
            }
        }

        &:nth-child(n+2) {
            // The rest of the children are now the official stocks
            padding-top: $gutter-width / 2;
            padding-bottom: $gutter-width / 2;

            border-top: 1px solid $gray;

            @include breakpoint (max-width $mobile-l) {
                border-top: none;
            }
        }
    }


    @include breakpoint( max-width $mobile-l) {

        &-name {
            margin-bottom: $gutter-width / 2;
        }

        &-price {
            text-align: left;
        }

        &-color {
            text-align: right;

            &:after {
                display: none;
            }
        }

        &-price, &-color {
            padding-bottom: 8px;
            border-bottom: 1px solid $gray;
        }
    }


}